<template>
  <div class="">
    <el-select
      v-model="warehousesModel"
      @change="warehousesChanged"
      autocomplete="new-password"
      :placeholder="$t('COMMON.WAREHOUSES')"
      :filterable="filterable"
      :multiple="true"
      :disabled="disabled"
      remote
      :remote-method="getWarehouses"
      :loading="loading"
    >
      <el-option v-if="showAll" :value="null" :label="$t('COMMON.WAREHOUSES')">
      </el-option>
      <el-option
        v-for="warehouse in warehousesList"
        :key="warehouse.id"
        :value="warehouse.id"
        :label="`${warehouse.name}`"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { Option, Select } from "element-ui";
import { uniqBy } from "lodash";

export default {
  name: "warehouses-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    isModel: {
      type: Boolean,
      default: false,
      description: "Can filter only models warehouse",
    },
    warehouses: {
      type: Array,
      default: () => [],
      description: "Warehouses id array",
    },
    filterIdsNotIn: {
      type: Array,
      default: () => [],
      description: "Warehouse not in list id",
    },
    filterReseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterIsModel: {
      type: Boolean,
      default: null,
      description: "Only select models or not models",
    },
    includes: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      warehousesModel: this.warehouses.map((item) => item.id),
      warehousesList: [],
      fetchedWarehouses: [],
      loading: false,
    };
  },

  setup() {},

  created() {
    this.getWarehouses(null, this.warehouses);
  },

  methods: {
    async getWarehouses(query = null, ids = null) {
      try {
        this.loading = true;
        let params = {
          sort: "-name",
          filter: {
            ...(query ? { search: query } : {}),
            ...(ids?.length ? { ids: ids.map((item) => item.id) } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
          include: this.includes,
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }
        if (this.isModel) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              isModel: this.isModel,
            },
          };
        } else {
          params = {
            ...params,
            filter: {
              ...params.filter,
              isModel: false,
            },
          };
        }
        if (this.filterReseller) {
          params = {
            ...params,
            filter: { ...params.filter, reseller: this.filterReseller },
          };
        }
        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.filterIdsNotIn.length > 0) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              idsNotIn: this.filterIdsNotIn,
            },
          };
        }
        if (this.filterIsModel != null) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              isModel: this.filterIsModel,
            },
          };
        }
        await this.$store.dispatch("warehouses/list", params);
        const warehousesArr = await this.$store.getters["warehouses/list"];
        this.warehousesList = warehousesArr;
        this.fetchedWarehouses = uniqBy(
          [...this.fetchedWarehouses, ...warehousesArr],
          (item) => item.id
        );
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    warehousesChanged(warehouses) {
      this.$emit(
        "warehousesChanged",
        this.fetchedWarehouses.filter((item) =>
          warehouses.includes(item.id.toString())
        )
      );
    },
  },

  watch: {
    warehouses(warehouses) {
      this.warehousesModel = warehouses;
      this.getWarehouses(null, warehouses);
    },
    filterOrganization(filterOrganization) {
      this.getWarehouses();
    },
    filterIdsNotIn(filterIdsNotIn) {
      this.getWarehouses();
    },
    filterReseller() {
      this.getWarehouses();
    },
  },
};
</script>
