<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h3 class="mb-5">
          {{ $t("SUPPLIERS.LAST_SYNC_PRODUCTS") }} -
          {{ $timeZoneDateFormat(syncResults.created_at) }} -
          <a
            target="_blank"
            :href="syncResults.file_url"
            :title="$t('SUPPLIERS.SHOW_FILE')"
          >
            <i class="fa fa-file" />
          </a>
        </h3>
      </div>
    </div>
    <div>
      <div class="col-12 row mb-3">
        <div class="col-6 col-md-3">
          <base-input>
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              :placeholder="$t('COMMON.PER_PAGE')"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-6 col-md-3">
          <base-input>
            <el-select
              class="select-primary pagination-select"
              v-model="selectedType"
              :placeholder="$t('COMMON.TYPE')"
            >
              <el-option
                class="select-primary"
                :label="$t('COMMON.ALL')"
                :value="null"
              >
              </el-option>
              <el-option
                class="select-primary"
                :label="$t('SUPPLIERS.PRODUCTS_CREATED')"
                value="added"
              >
              </el-option>
              <el-option
                class="select-primary"
                :label="$t('SUPPLIERS.PRODUCTS_UPDATED')"
                value="updated"
              >
              </el-option>
              <el-option
                class="select-primary"
                :label="$t('SUPPLIERS.PRODUCTS_DELETED')"
                value="deleted"
              >
              </el-option>
              <el-option
                class="select-primary"
                :label="$t('SUPPLIERS.PRODUCTS_UNTOUCHED')"
                value="untouched"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-6 col-md-3">
          <base-input
            v-model="query"
            type="search"
            prepend-icon="fas fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
        </div>
        <div class="col-6 col-md-3" v-if="selectedRows.length > 0">
          <base-button
            @click="
              () => {
                warehousesSelectorModalOpened = true;
                actionDelete = false;
                actionAdd = true;
              }
            "
            type="button"
            class="btn btn-sm btn-primary text-uppercase px-3 col-12"
          >
            {{ $t("SUPPLIERS.ADD_TO_WAREHOUSE_MODELS") }}
          </base-button>
        </div>
        <div class="col-6 col-md-3" v-if="selectedRows.length > 0">
          <base-button
            @click="
              () => {
                warehousesSelectorModalOpened = true;
                actionAdd = false;
                actionDelete = true;
              }
            "
            type="button"
            class="btn btn-sm btn-danger text-uppercase px-3 col-12"
          >
            {{ $t("SUPPLIERS.DELETE_FROM_WAREHOUSE_MODELS") }}
          </base-button>
        </div>
        <div v-if="loading" class="col-6 col-md-3">
          <i class="fa fa-2x fa-spinner fa-spin"></i>
        </div>
      </div>

      <el-table
        class="align-items-center table-flush"
        header-row-class-name="thead-light"
        :data="products"
        @sort-change="sortChange"
        @selection-change="handleSelectionChange"
      >
        <div slot="empty" v-if="loading">
          <img src="/img/loading.gif" style="height: 100px; width: 100px" />
        </div>
        <el-table-column type="selection" width="55px" />
        <el-table-column label="" min-width="120px">
          <template v-slot="{ row }">
            <badge
              v-if="syncResults.added_products.includes(+row.id)"
              type="success"
            >
              {{ $t(`SUPPLIERS.PRODUCTS_CREATED`) }}
            </badge>
            <badge
              v-if="syncResults.updated_products.includes(+row.id)"
              type="warning"
            >
              {{ $t(`SUPPLIERS.PRODUCTS_UPDATED`) }}
            </badge>
            <badge
              v-if="syncResults.deleted_products.includes(+row.id)"
              type="danger"
            >
              {{ $t(`SUPPLIERS.PRODUCTS_DELETED`) }}
            </badge>
            <badge
              v-if="syncResults.untouched_products.includes(+row.id)"
              type="info"
            >
              {{ $t(`SUPPLIERS.PRODUCTS_UNTOUCHED`) }}
            </badge>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('COMMON.CODE')"
          prop="code"
          sortable="custom"
          min-width="200px"
        />
        <el-table-column
          :label="$t('PRODUCTS.SKU')"
          prop="sku"
          sortable="custom"
          min-width="250px"
        />
        <el-table-column
          :label="$t('COMMON.NAME')"
          prop="name"
          sortable="custom"
          min-width="250px"
        />
        <el-table-column
          :label="$t('COMMON.SELLING_PRICE')"
          prop="selling_price"
          sortable="custom"
          min-width="200px"
        >
          <template v-slot="{ row }">
            <span v-if="row.can_be_sold">
              {{ $formatCurrency(row.selling_price) }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('COMMON.BUYING_PRICE')"
          prop="selling_price"
          sortable="custom"
          min-width="200px"
        >
          <template v-slot="{ row }">
            <span v-if="row.can_be_purchased">
              {{ $formatCurrency(row.buying_price) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column min-width="100px" align="center" fixed="right">
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip
              :content="$t('COMMON.VIEW')"
              placement="top"
              v-if="$currentUserCan($permissions.PERM_VIEW_PRODUCTS)"
            >
              <a
                type="text"
                @click="viewProduct(row)"
                class="table-action"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-eye"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div class="row px-3 mt-3">
      <div v-if="loading" class="col-12 text-center">
        <i class="fas fa-spinner fa-spin fa-2x"></i>
      </div>
    </div>
    <div
      slot="footer"
      class="mt-5 col-12 d-flex justify-content-center justify-content-sm-between flex-wrap table-footer"
    >
      <div class="">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}

          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>

    <modal
      :show.sync="warehousesSelectorModalOpened"
      modal-classes="modal-secondary"
      size="xl"
    >
      <div>
        <base-input
          :label="`${$t('COMMON.WAREHOUSES')}`"
          :placeholder="$t('COMMON.WAREHOUSES')"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_WAREHOUSES)"
        >
          <warehouses-selector
            :warehouses="selectedWarehouses"
            :filterable="true"
            :showAll="false"
            :multiple="true"
            :filterIsModel="true"
            :organization="supplier.organization.id"
            @warehousesChanged="
              (warehouses) => {
                this.selectedWarehouses = warehouses.map((item) => item.id);
              }
            "
          />
        </base-input>

        <div class="my-4">
          <base-button
            v-if="actionAdd"
            type="button"
            class="btn btn-sm btn-primary"
            native-type="submit"
            @click="addToWarehouseModels"
          >
            {{ $t("SUPPLIERS.ADD_TO_WAREHOUSE_MODELS") }}
          </base-button>

          <base-button
            v-if="actionDelete"
            type="button"
            class="btn btn-sm btn-primary"
            native-type="submit"
            @click="deleteFromWarehouseModels"
          >
            {{ $t("SUPPLIERS.DELETE_FROM_WAREHOUSE_MODELS") }}
          </base-button>
        </div>
      </div>
    </modal>

    <modal
      :show.sync="resultModalOpened"
      modal-classes="modal-secondary"
      size="xl"
    >
      <div>
        <el-table
          :data="result"
          style="width: 100%"
          type="expand"
          row-key="warehouse_id"
        >
          <el-table-column prop="line" :label="$t('COMMON.WAREHOUSE')">
            <template v-slot="{ row }">
              {{ `${row.warehouse_name}` }}
            </template>
          </el-table-column>
          <el-table-column
            prop="line"
            :label="$t('SUPPLIERS.PRODUCTS_PROCESSED')"
          >
            <template v-slot="{ row }">
              {{ `${row.processed_products.length}` }}
            </template>
          </el-table-column>

          <el-table-column prop="line" :label="$t('SUPPLIERS.PRODUCTS_ERRORS')">
            <template v-slot="{ row }">
              {{ `${row.errors.length}` }}
            </template>
          </el-table-column>

          <el-table-column type="expand">
            <template slot-scope="props">
              <el-table
                :data="props.row.errors"
                style="width: 100%"
                type="expand"
                row-key="id"
              >
                <el-table-column prop="id" :label="$t('SUPPLIERS.PRODUCT')">
                  <template v-slot="{ row }">
                    {{ `${row.product_name}` }}
                  </template>
                </el-table-column>
                <el-table-column prop="name" :label="$t('SUPPLIERS.ERROR')">
                  <template v-slot="{ row }">
                    {{ row.message }}
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </modal>
  </div>
</template>
<script>
import { capitalize } from "lodash";
import swal from "sweetalert2";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import Badge from "@/components/Badge.vue";
import WarehousesSelector from "@/components/WarehousesSelector.vue";

export default {
  name: "supplier-view-supplier-products",

  components: {
    Badge,
    WarehousesSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  props: ["supplier", "syncResults"],

  mixins: [requestErrorMixin],

  data() {
    return {
      selectedRows: [],
      query: null,
      total: 0,
      pagination: {
        perPage: 100,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500, 1000, 10000, 100000],
      },
      loading: true,
      selectedType: null,
      products: [],
      warehousesSelectorModalOpened: false,
      selectedWarehouses: [],
      resultModalOpened: false,
      result: [],
      actionAdd: false,
      actionDelete: false,
    };
  },

  mounted() {},

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
            supplierProduct: this.supplier.id,
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "",
        };

        if (this.selectedType) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              [`syncResults${capitalize(this.selectedType)}`]:
                this.syncResults.id,
            },
          };
        } else {
          params = {
            ...params,
            filter: {
              ...params.filter,
              syncResults: this.syncResults.id,
            },
          };
        }

        await this.$store.dispatch("products/list", params);
        this.products = this.$store.getters["products/list"];
        this.total = this.$store.getters["products/listTotal"];
        this.loading = false;
        this.scrollToTop();
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },

    handleSelectionChange(val) {
      this.selectedRows = val.map((item) => item.id);
    },

    async addToWarehouseModels() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const results = await this.$store.dispatch(
          "warehouseProducts/bulkCreate",
          {
            warehouses: [...this.selectedWarehouses],
            products: [...this.selectedRows],
          }
        );
        this.result = results;
        this.warehousesSelectorModalOpened = false;
        this.resultModalOpened = true;
        this.selectedWarehouses = [];
      } catch (error) {
        await this.showRequestError(error);
      }
      swal.close();
    },

    async deleteFromWarehouseModels() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const results = await this.$store.dispatch(
          "warehouseProducts/bulkDelete",
          {
            warehouses: [...this.selectedWarehouses],
            products: [...this.selectedRows],
          }
        );
        this.result = results;
        this.warehousesSelectorModalOpened = false;
        this.resultModalOpened = true;
        this.selectedWarehouses = [];
      } catch (error) {
        await this.showRequestError(error);
      }
      swal.close();
    },

    viewProduct(product) {
      this.$router.push({
        name: "View Product",
        params: { id: product.id },
      });
    },

    scrollToTop() {
      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: "smooth", // For smooth scrolling
      });
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    selectedType: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },
};
</script>
