<template>
  <div>
    <el-table
      :data="synchronisationResult"
      style="width: 100%"
      type="expand"
      row-key="id"
    >
      <el-table-column prop="line" :label="$t('SUPPLIERS.PRODUCTS_CREATED')">
        <template v-slot="{ row }">
          {{ `${row.added.length}` }}
        </template>
      </el-table-column>
      <el-table-column prop="line" :label="$t('SUPPLIERS.PRODUCTS_UPDATED')">
        <template v-slot="{ row }">
          {{ `${row.updated.length}` }}
        </template>
      </el-table-column>
      <el-table-column prop="line" :label="$t('SUPPLIERS.PRODUCTS_DELETED')">
        <template v-slot="{ row }">
          {{ `${row.deleted.length}` }}
        </template>
      </el-table-column>
      <el-table-column prop="line" :label="$t('SUPPLIERS.PRODUCTS_ERRORS')">
        <template v-slot="{ row }">
          {{ `${row.errors.length}` }}
        </template>
      </el-table-column>

      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table
            :data="props.row.errors"
            style="width: 100%"
            type="expand"
            row-key="id"
          >
            <el-table-column prop="id" :label="$t('SUPPLIERS.PRODUCT')">
              <template v-slot="{ row }">
                {{ `${row.name}` }}
              </template>
            </el-table-column>
            <el-table-column prop="name" :label="$t('SUPPLIERS.ERROR')">
              <template v-slot="{ row }">
                {{ row.error }}
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="prev, pager, next"
      :total="synchronisationResult.length"
    ></el-pagination>
  </div>
</template>
<script>
import {
  Collapse,
  CollapseItem,
  Pagination,
  Table,
  TableColumn,
} from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },

  props: ["synchronisationResult"],

  mixins: [requestErrorMixin],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {},
};
</script>
