var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid mt-5"},[_c('div',[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.supplier.company_name)+" "),(_vm.$currentUserCan(_vm.$permissions.PERM_EDIT_SUPPLIERS))?_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm","disabled":_vm.loading},on:{"click":function($event){return _vm.editSupplier()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-edit"})])]):_vm._e(),(
                  _vm.$currentUserCan(_vm.$permissions.PERM_EDIT_USERS) &&
                  !_vm.supplier.active
                )?_c('base-button',{attrs:{"type":"info","icon":"","size":"sm","disabled":_vm.loading},on:{"click":function($event){return _vm.activateSupplier()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-power-off"}),_vm._v(" "+_vm._s(_vm.$t("SUPPLIERS.ACTIVATE"))+" ")])]):_vm._e(),(
                  _vm.$currentUserCan(_vm.$permissions.PERM_EDIT_USERS) && _vm.supplier.active
                )?_c('base-button',{attrs:{"type":"warning","icon":"","size":"sm","disabled":_vm.loading},on:{"click":function($event){return _vm.deactivateSupplier()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-power-off"}),_vm._v(" "+_vm._s(_vm.$t("SUPPLIERS.DEACTIVATE"))+" ")])]):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_DELETE_SUPPLIERS))?_c('base-button',{attrs:{"type":"danger","icon":"","size":"sm","disabled":_vm.loading},on:{"click":function($event){return _vm.deleteSupplier()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()],1)]),_c('div',{staticClass:"col-4 text-right"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.goBack()}}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.RETURN_TO_LIST"))+" ")]),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_SUPPLIERS))?_c('notification-subscription',{attrs:{"objectType":'suppliers',"objectId":_vm.supplier.id,"events":{
                UPDATE: _vm.$t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: _vm.$t('NOTIFICATIONS.EVENT_DELETE'),
              }}}):_vm._e()],1)])]),_c('div',[_c('div',{staticClass:"col-12 justify-content-center justify-content-sm-between flex-wrap"},[_c('tabs',{key:_vm.renderKey,staticClass:"flex-column flex-md-row",attrs:{"fill":"","tabNavWrapperClasses":"nav-wrapper","tabNavClasses":"nav nav-pills nav-fill","value":"global"}},[_c('card',{attrs:{"shadow":""}},[_c('tab-pane',{attrs:{"title":"global","id":"1","active":true}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"ni ni-cloud-upload-96"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.GLOBAL"))+" ")]),_c('supplier-view-global',{attrs:{"supplier":_vm.supplier}})],1),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_CONTACTS))?_c('tab-pane',{attrs:{"title":"contacts","id":"2","active":true}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"ni ni-circle-08"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.CONTACTS"))+" ")]),_c('supplier-view-contacts',{attrs:{"supplier":_vm.supplier}})],1):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_PRODUCTS))?_c('tab-pane',{attrs:{"title":"supplier-products","id":"3"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa fa-cubes"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.PRODUCTS"))+" ")]),_c('supplier-view-supplier-products',{attrs:{"supplier":_vm.supplier},on:{"supplierUpdated":_vm.get}})],1):_vm._e(),(
                  _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_PRODUCTS) &&
                  _vm.supplier.syncResults.length > 0
                )?_c('tab-pane',{attrs:{"title":"supplier-products-last-sync-results","id":"4"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa fa-clipboard-check"}),_vm._v(" "+_vm._s(_vm.$t("SUPPLIERS.LAST_SYNC_PRODUCTS"))+" ")]),_c('supplier-view-last-sync-results',{attrs:{"supplier":_vm.supplier,"syncResults":_vm.supplier.syncResults[0]},on:{"supplierUpdated":_vm.get}})],1):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_FILES))?_c('tab-pane',{attrs:{"title":"files","id":"998"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"ni ni-folder-17"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.FILES"))+" ")]),_c('list-file-component',{attrs:{"object":_vm.supplier}})],1):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOGS))?_c('tab-pane',{attrs:{"title":"logs","id":"999"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa fa-file"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.LOGS"))+" ")]),_c('supplier-view-logs',{attrs:{"supplier":_vm.supplier}})],1):_vm._e()],1)],1)],1)])],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }